'use client';
import { Popup } from '@/components/core/Popup/Popup';
import { NEXT_PUBLIC_SITE_URL } from '@/config/env';
import { useState } from 'react';
import { FiShare2 } from 'react-icons/fi';
import styles from './ShareButton.module.scss';

export default function ShareButton({ code }: { code: string | undefined }) {
    const [show, setShow] = useState(false);
    return (
        <div className={styles.root}>
            <FiShare2
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    setShow(true);
                    navigator.clipboard.writeText(`${NEXT_PUBLIC_SITE_URL}/share/profile/${code}`);
                }}
            />
            <Popup className={styles.popup} show={show} closePopup={setShow} />
        </div>
    );
}
