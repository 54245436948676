import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

type AutoCompleteLocationProps = {
    type?: 'country' | '(cities)' | "geocode" | "address";
    field: any;
    placeholder: string;
    isMulti?: boolean;
};
const AutoCompleteLocation: React.FC<AutoCompleteLocationProps> = ({ field, type, placeholder, isMulti = false }) => {
    return (
        <>
            {type !== undefined ? (
                <div>
                    <GooglePlacesAutocomplete
                        apiKey={'AIzaSyB-uqCKLuiP77BYZeIyBapaAZpQye45SKw'}
                        selectProps={{
                            ...field,
                            isClearable: true,
                            isMulti: isMulti,
                            placeholder: placeholder,
                            styles: {
                                input: provided => ({
                                    ...provided,
                                    color: '#1d2525',
                                }),
                                option: provided => ({
                                    ...provided,
                                    backgroundColor: '#ccd2ca',
                                    borderRadius: '12px',
                                }),
                                singleValue: provided => ({
                                    ...provided,
                                    color: '#1d2525',
                                }),
                                multiValue: provided => ({
                                    ...provided,
                                    color: '#1d2525',
                                    backgroundColor: 'rgba(117, 137, 116, 0.60)',
                                }),
                                container: provided => ({
                                    ...provided,
                                    backgroundColor: '#ccd2ca',
                                    borderRadius: '12px',
                                    boxShadow: 'none',
                                }),
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: '#ccd2ca',
                                    borderRadius: '12px',
                                    backgroundColor: '#ccd2ca',
                                }),
                                menu: provided => ({
                                    ...provided,
                                    backgroundColor: '#ccd2ca',
                                    borderRadius: '12px',
                                }),
                                indicatorSeparator: baseStyles => ({
                                    ...baseStyles,
                                    color: '#758974',
                                }),
                                clearIndicator: baseStyles => ({
                                    ...baseStyles,
                                    color: '#758974',
                                }),
                                dropdownIndicator: provided => ({
                                    ...provided,
                                    color: 'transparent',
                                }),
                            },
                        }}
                        autocompletionRequest={{ types: [type] }}
                        apiOptions={{ language: 'en' }}
                    />
                </div>
            ) : (
                <GooglePlacesAutocomplete
                    apiKey={'AIzaSyB-uqCKLuiP77BYZeIyBapaAZpQye45SKw'}
                    selectProps={{
                        ...field,
                        isClearable: true,
                        placeholder: placeholder,
                        styles: {
                            input: provided => ({
                                ...provided,
                                color: '#1d2525',
                            }),
                            option: provided => ({
                                ...provided,
                                backgroundColor: '#ccd2ca',
                            }),
                            singleValue: provided => ({
                                ...provided,
                                color: '#1d2525',
                            }),
                            multiValue: provided => ({
                                ...provided,
                                color: '#1d2525',
                                backgroundColor: 'rgba(117, 137, 116, 0.60)',
                            }),
                            container: provided => ({
                                ...provided,
                                backgroundColor: '#ccd2ca',
                                borderRadius: '12px',
                                boxShadow: 'none',
                            }),
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: '#ccd2ca',
                                borderRadius: '12px',
                                backgroundColor: '#ccd2ca',
                            }),
                            menu: provided => ({
                                ...provided,
                                backgroundColor: '#ccd2ca',
                                borderRadius: '12px',
                            }),
                            indicatorSeparator: baseStyles => ({
                                ...baseStyles,
                                color: '#758974',
                            }),
                            clearIndicator: baseStyles => ({
                                ...baseStyles,
                                color: '#758974',
                            }),
                            dropdownIndicator: provided => ({
                                ...provided,
                                color: 'transparent',
                            }),
                        },
                    }}
                />
            )}
        </>
    );
};
export default AutoCompleteLocation;
