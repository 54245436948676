'use client';
import AutoCompleteLocation from '@/components/AutoCompleteLocation/AutoCompleteLocation';
import { Controller, useForm } from 'react-hook-form';
import styles from './SearchBar.module.scss';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

type SearchFormType = {
    country: any;
};

const SearchBar = () => {
    const searchParams = useSearchParams();
    const router = useRouter();
    const { control, register, watch } = useForm<SearchFormType>();
    const country = watch('country');
    useEffect(() => {
        if (country) {
            const params = new URLSearchParams(searchParams);
            params.set('query', country.label);
            params.set('page', '1');
            router.push(`/search?${params.toString()}`);
        }
    }, [country, router, searchParams]);

    return (
        <div className={styles.container}>
            <Controller
                {...register('country')}
                name="country"
                control={control}
                render={({ field }) => <AutoCompleteLocation field={field} type={'country'} placeholder={'Enter a country'} />}
            />
        </div>
    );
};
export default SearchBar;
