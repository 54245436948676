import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export const useBreakpoints = () => {
    const isXSmall = useMediaQuery({ query: '(max-width: 349px)' });
    const isMobile = useMediaQuery({ query: '(min-width: 350px) and (max-width: 479px)' });
    const isTablet = useMediaQuery({ query: '(min-width: 480px) and (max-width: 1023px)' });
    const isDesktop = useMediaQuery({ query: '(min-width: 1024px) and (max-width: 2880px)' });
    const [breakPoints, setBreakPoints] = useState<any>({ isLarge: true });

    useEffect(() => {
        setBreakPoints({
            isXSmall,
            isMobile,
            isTablet,
            isDesktop,
        });
    }, [
        isXSmall,
        isMobile,
        isTablet,
        isDesktop,
    ]);

    return breakPoints;
};
