'use client';
import clsx from 'clsx';

import { useEffect } from 'react';
import styles from './Popup.module.scss';

export const Popup = ({
    className,
    show,
    closePopup,
}: {
    className?: string;
    show: boolean;
    closePopup: (bool: boolean) => void;
}) => {
    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;
        if (show) {
            timeout = setTimeout(() => {
                closePopup(false);
            }, 3000);
        }
        return () => clearTimeout(timeout);
    }, [show, closePopup]);

    return (
        <div className={clsx(className, styles.popup, { [styles.show]: show })}>
            <div>Shortened</div>
            <div>URL is copied</div>
        </div>
    );
};
