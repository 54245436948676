import { MutableRefObject, useEffect } from 'react';

export function useHandleClickOutside(show: boolean, callback: (arg: boolean) => void, ref: MutableRefObject<any>) {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent | TouchEvent) => {
            if (show && ref.current && !ref.current.contains(event.target as Node)) {
                callback(false);
            }
        };
        document.addEventListener('touchstart', e => handleClickOutside(e));
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('touchstart', e => handleClickOutside(e));
            document.removeEventListener('click', handleClickOutside);
        };
    }, [callback, show, ref]);
}
