'use client';
import CloseIcon from '@/assets/icons/close';
import HorizontalDark from '@/assets/logo/HorizontalDark';
import HorizontalLight from '@/assets/logo/HorizontalLight';
import clsx from 'clsx';
import { signOut } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { IoIosLogIn, IoIosLogOut } from 'react-icons/io';
import { RxHamburgerMenu } from 'react-icons/rx';
import Author from '../Author/Author';
import SearchBar from './_components/SearchBar/SearchBar';
import { Typography } from '../typography/Typography';
import styles from './Menu.module.scss';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import { useHandleClickOutside } from '@/hooks/useHandleClickOutside';
import { usePathname } from 'next/navigation';

type MenuProps = {
    isOpaque: boolean;
    isReverseOrder?: boolean;
    session: any;
};

const Menu: React.FC<MenuProps> = ({ isOpaque, session, isReverseOrder = false }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const t = useTranslations('menu');
    const { isDesktop } = useBreakpoints();
    const searchbarRef = useRef<any>(null);
    const pathname = usePathname();

    const handleMenuClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useHandleClickOutside(showSearch, setShowSearch, searchbarRef);

    return isDesktop ? (
        <nav>
            <div
                className={clsx(
                    styles.desktopcontainer,
                    isOpaque && !pathname.includes('profile') && styles.desktopOpaqueContainer,
                    isReverseOrder && styles.desktopMenu,
                )}
            >
                <div className={styles.row}>
                    <Link className={styles.logo} href={'/en'} aria-label={'logo'}>
                        {isOpaque && !pathname.includes('profile') ? (
                            <HorizontalLight width={'102'} height={'32'} />
                        ) : (
                            <HorizontalDark width={'102'} height={'32'} />
                        )}
                    </Link>
                    <div className={styles.rightside}>
                        <Link href={'/faq'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Typography.PStrong classname={styles.menuItem}>{t('faq')}</Typography.PStrong>
                        </Link>
                        <Link href={'/about'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Typography.PStrong classname={styles.menuItem}>{t('about')}</Typography.PStrong>
                        </Link>
                        {!pathname.includes(`profile/${session?.user.uuid}`) ? (
                            <div className={styles.search}>
                                <Link href={'/contact'} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Typography.PStrong classname={styles.menuItem}>{t('contact')}</Typography.PStrong>
                                </Link>
                                {!pathname.includes('search') && showSearch ? (
                                    <div ref={searchbarRef}>
                                        <SearchBar />
                                    </div>
                                ) : (
                                    <>
                                        {!pathname.includes('search') && (
                                            <span className={styles.iconContainer} onClick={() => setShowSearch(true)}>
                                                <FiSearch className={styles.icon} />
                                            </span>
                                        )}
                                        <div className={styles.verticleline} />
                                    </>
                                )}
                                {session?.user.uuid ? (
                                    <Link
                                        href={`/profile/${session.user.uuid}`}
                                        style={{ color: 'inherit', textDecoration: 'none' }}
                                    >
                                        <Author
                                            image={session.user.image!}
                                            name={session.user.name.split(' ')[0]}
                                            isSmallSize
                                            isMenuBar
                                        />
                                    </Link>
                                ) : (
                                    <Link
                                        className={styles.iconrow}
                                        href={'/login'}
                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                    >
                                        <Typography.P>{t('loginButton')}</Typography.P>
                                        <span className={styles.iconcontainer}>
                                            <IoIosLogIn className={styles.icon} />
                                        </span>
                                    </Link>
                                )}
                            </div>
                        ) : (
                            <Link href={'/contact'} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Typography.PStrong classname={styles.menuItem}>{t('contact')}</Typography.PStrong>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    ) : (
        <>
            <nav aria-labelledby="open menu">
                <div
                    className={clsx(
                        isReverseOrder ? styles.profileMenuContainer : styles.menuContainer,
                        isReverseOrder && isMenuOpen
                            ? styles.openProfileMenuContainer
                            : isReverseOrder && styles.animateProfileMenuClose,
                        !isReverseOrder && isMenuOpen
                            ? styles.openMenuContainer
                            : !isReverseOrder && styles.animateMenuClose,
                        { ['bodyNoScroll']: isMenuOpen },
                    )}
                >
                    <div className={styles.menuCloseRow}>
                        <div className={styles.logo} aria-label={'logo'}>
                            <HorizontalLight width={'76'} height={'25'} />
                        </div>
                        <span tabIndex={0} role="button" aria-label={'close menu'} onClick={handleMenuClick}>
                            <CloseIcon isPrimaryColor />
                        </span>
                    </div>
                    <div className={styles.menuItems}>
                        <Link href={'/'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Typography.P classname={styles.menuItem}>{t('home')}</Typography.P>
                        </Link>
                        <Link href={'/faq'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Typography.P classname={styles.menuItem}>{t('faq')}</Typography.P>
                        </Link>
                        <Link href={'/about'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Typography.P classname={styles.menuItem}>{t('about')}</Typography.P>
                        </Link>
                        <Link href={'/content-creators'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Typography.P classname={styles.menuItem}>{t('collaborate')}</Typography.P>
                        </Link>
                        <Link href={'/contact'} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Typography.P classname={styles.menuItem}>{t('contact')}</Typography.P>
                        </Link>
                        <span style={{ paddingTop: '32px' }}>
                            <hr />
                        </span>
                        {session?.user.uuid && (
                            <div className={styles.accountSection}>
                                <Link
                                    href={`/profile/${session.user.uuid}`}
                                    style={{ color: 'inherit', textDecoration: 'none' }}
                                >
                                    <Author
                                        image={session.user.image!}
                                        name={session.user.name}
                                        isSmallSize
                                        isMenuBar
                                    />
                                </Link>
                                <div className={styles.iconrow} onClick={() => signOut({ callbackUrl: '/' })} style={{cursor: 'pointer'}}>
                                    <Typography.P>{t('logoutButton')}</Typography.P>
                                    <span className={styles.iconcontainer}>
                                        <IoIosLogOut className={styles.icon} />
                                    </span>
                                </div>
                            </div>
                        )}
                        {!session?.user.uuid && (
                            <div className={styles.accountSection}>
                                <Typography.H4>{t('loginTitle')}</Typography.H4>
                                <Link
                                    className={styles.iconrow}
                                    href={'/login'}
                                    style={{ textDecoration: 'none', color: 'inherit' }}
                                >
                                    <Typography.P>{t('loginButton')}</Typography.P>
                                    <span className={styles.iconcontainer}>
                                        <IoIosLogIn className={styles.icon} />
                                    </span>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </nav>

            {showSearch ? (
                <div ref={searchbarRef}>
                    <SearchBar />
                </div>
            ) : (
                <nav
                    className={clsx(styles.root, isOpaque && styles.opaqueroot, isReverseOrder && styles.reverseOrder)}
                    aria-labelledby="closed menu"
                >
                    <span className={clsx(styles.iconContainer)} onClick={handleMenuClick}>
                        <RxHamburgerMenu className={styles.icon} />
                    </span>
                    <div className={styles.logo} aria-label={'logo'}>
                        {isOpaque ? (
                            <HorizontalLight width={'72'} height={'24'} />
                        ) : (
                            <HorizontalDark width={'72'} height={'24'} />
                        )}
                    </div>
                    {!isReverseOrder && (
                        <span className={styles.iconContainer} onClick={() => setShowSearch(true)}>
                            <FiSearch className={styles.icon} />
                        </span>
                    )}
                </nav>
            )}
        </>
    );
};
export default Menu;
